body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} 


.collapsible-content-edonec {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  border-bottom: 1px solid #dee2e6 !important;
  height: 40px;
}
.title-text-edonec {
  display: block;
  font-size: 1em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.collapsible-header-edonec {
  display: flex;
  justify-content: space-between;
  padding: 2px 20px 2px 20px;
  border-bottom: 1px solid #dee2e6 !important;
  background: white;
}
.collapsible-content-padding-edonec {
  padding: 20px 20px 20px 20px;
  background: white;
}
.rotate-center-edonec {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.rotate-center-edonec.down {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rotate-center-edonec.up {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.collapsible-icon-button-edonec {
  cursor: pointer;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.collapsible-card-edonec {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin-bottom:20px;
}

.collapsible-card-edonec:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.readonyLabel{ 
  width: 100%; 
  overflow-y:auto;
  white-space: normal;  
  display: block;  
  background-color: #e5e1e1;
  color: rgb(96, 94, 92);
  border: solid 0.1px;
  cursor: not-allowed;
  padding: 7px;
  max-height: 108px;
}

.customGridCell{
  font-size: 14px !important;
  color: rgb(50, 49, 48) !important;
  text-align: left !important;
}  

.customSortIcon{
  float: right !important;
}

#Dialog99-title{
  border-bottom: 1px solid #c2bfbf;
  margin-bottom:10px;
}

.ms-ChoiceField{
  padding-right: 10px;
  }

.ms-Checkbox-checkbox {
    height: 17px;
    width: 17px;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-gap: 16px; /* Adjust the gap between cards */
}